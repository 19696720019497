(function () {
  'use strict';

  /* @ngdoc object
   * @name ajuntaments.ajuntament
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament', [
    'neo.home.ajuntaments.ajuntament.informacio'
  ]);
}());
